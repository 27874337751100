<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col">
        <div class="row text-center mb-5">
          <div class="col">
            <h2
              data-aos-duration="2000"
              data-aos="fade-right"
              class="display-4 header-spacing moving-gradient-text fw-bolder"
            >
              OUR COLLECTIVE
            </h2>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-10 mx-auto">
            <div class="row mb-0 mb-md-5">
              <div class="col-8 mx-auto col-md-4 mb-5 mb-md-0 my-auto">
                <img
                  data-aos-duration="2000"
                  data-aos="fade-right"
                  class="animated"
                  src="@/assets/images/team/alex.jpg"
                  alt="Alex Friday Collective"
                  width="100%"
                />
              </div>
              <div class="col-12 col-md-8 my-auto">
                <h2
                  data-aos-duration="2000"
                  data-aos="fade-left"
                  class="mb-3 header-spacing moving-gradient-text fw-bolder"
                >
                  ALEX
                </h2>
                <p class="fw-bold">
                  FULL STACK DEV · INFRASTRUCTURE & ARCHITECTURE · GRYFFINDOR
                </p>
                <p data-aos-duration="2000" data-aos="fade-left">
                  Still waiting on my letter to go to Hogwarts! I decided to
                  become a wizard in real life, well the tech, nerdy, geeky kind
                  at least. I love creating solutions to challenging problems
                  and automate workflows. I have spent my life travelling the
                  planet, learning as much as I can each day. I am self-taught
                  Full stack developer, specialising in healthcare, property and
                  infrastructure.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mb-0 mb-md-5">
              <div class="col-12 col-md-8 my-auto">
                <h2
                  data-aos-duration="2000"
                  data-aos="fade-right"
                  class="mb-3 header-spacing moving-gradient-text fw-bolder"
                >
                  DEAN
                </h2>
                <p class="fw-bold">
                  FULL STACK DEV · SECURITY & COMPLIANCE · RAVENCLAW
                </p>
                <p data-aos-duration="2000" data-aos="fade-right">
                  I love to explore, from the endless prospects of cutting edge
                  tech to long days mountain biking in the Welsh valleys. Self
                  taught throughout childhood, I have a passion for designing
                  and building awesome applications. After studying ComSci at
                  Cardiff University, I moved to the city and started my
                  professional career spending the next 5 years
                  in Fintech as a systems engineer. Looking for something a bit more
                  colourful, I moved back to the valleys and now apply my skills
                  to the wonderful world of web!
                </p>
              </div>

              <div class="col-8 mx-auto col-md-4 mb-5 mb-md-0 my-auto">
                <img
                  data-aos-duration="2000"
                  data-aos="fade-left"
                  class="animated"
                  src="@/assets/images/team/dean.jpeg"
                  alt="Alex Friday Collective"
                  width="100%"
                />
              </div>
            </div>
            <!--  -->
            <div class="row mb-0 mb-md-5">
              <div class="col-8 mx-auto col-md-4 mb-5 mb-md-0 my-auto">
                <img
                  data-aos-duration="2000"
                  data-aos="fade-right"
                  class="animated"
                  src="@/assets/images/team/james.jpg"
                  alt="Alex Friday Collective"
                  width="100%"
                />
              </div>
              <div class="col-12 col-md-8 my-auto">
                <h2
                  data-aos-duration="2000"
                  data-aos="fade-left"
                  class="mb-3 header-spacing moving-gradient-text fw-bolder"
                >
                  JAMES
                </h2>
                <p class="fw-bold">
                  BUSINESS OPS · DESIGN & DEVELOPMENT · HUFFLEPUFF
                </p>
                <p data-aos-duration="2000" data-aos="fade-left">
                  Hey I'm James. I grew up in the North, after uni I had a 20s
                  crisis and escaped abroad living across Europe for most of the
                  next 10 years before heading back to London to join the
                  ratrace and enjoy suburbia. I'm passionate about travel and
                  finding crazy sporting adventures that take me across the
                  globe. My inner geek will find himself playing on the PS5 and
                  also loves his job, systems, finding solutions, and learning
                  something new, I'm always on a course or 2!
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mb-0 mb-md-5">
              <div class="col-12 col-md-8 my-auto">
                <h2
                  data-aos-duration="2000"
                  data-aos="fade-left"
                  class="mb-3 header-spacing moving-gradient-text fw-bolder"
                >
                  NADIA
                </h2>
                <p class="fw-bold">
                  DIGITAL MARKETING · STRATEGIST & BIZDEV · GRYFFINDOR
                </p>
                <p data-aos-duration="2000" data-aos="fade-left">
                  After many trips around the world, I've had quite a colourful
                  and varied career to date, all of which has moulded and shaped
                  who & what I am today.
                  <br />
                  You'll find me, stood still in various places, phone and
                  laptop one hand, baby, cocker spaniel and three cats in the
                  other.
                  <br />
                  Openly addicted to animals, tech, all things digital and
                  coffee.
                </p>
              </div>

              <div class="col-8 mx-auto col-md-4 mb-5 mb-md-0 my-auto">
                <img
                  data-aos-duration="2000"
                  data-aos="fade-right"
                  class="animated"
                  src="@/assets/images/team/nadia.jpg"
                  alt="Alex Friday Collective"
                  width="100%"
                />
              </div>
            </div>
            <div class="row mb-0 mb-md-5">
              <div class="col-8 mx-auto col-md-4 mb-5 mb-md-0 my-auto">
                <img
                  data-aos-duration="2000"
                  data-aos="fade-left"
                  class="animated"
                  src="@/assets/images/team/lil.jpeg"
                  alt="Alex Friday Collective"
                  width="100%"
                />
              </div>
              <div class="col-12 col-md-8 my-auto">
                <h2
                  data-aos-duration="2000"
                  data-aos="fade-left"
                  class="mb-3 header-spacing moving-gradient-text fw-bolder"
                >
                  LIL
                </h2>
                <p class="fw-bold">
                  JUNIOR DEVELOPER · DIGITAL MARKETING · HUFFLEPUFF
                </p>
                <p data-aos-duration="2000" data-aos="fade-left">
                  If you hadn’t noticed, several of us here are Harry Potter
                  fans. I’m a happy Hufflepuff enjoying the country lifestyle,
                  along with the occasional cheese-fest and on-tap wine, here in
                  the South of France.
                  <br />
                  Due to the above, I came to Social Media Marketing and Web
                  Design a tad later. But as often happens with late bloomers, I
                  am loving every minute of this journey and have finally found
                  something I am truly passionate about.
                  <br />
                  Fluent in English, French, and Cat.
                </p>
              </div>
            </div>
            <!--  -->
            <div class="row mb-0 mb-md-5">
              <div class="col-12 col-md-8 my-auto">
                <h2
                  data-aos-duration="2000"
                  data-aos="fade-right"
                  class="mb-4 header-spacing moving-gradient-text fw-bolder"
                >
                  KATI-LOU
                </h2>
                <p class="fw-bold">
                  CONTENT CREATOR · DIGITAL MARKETING · GRYFFINDOR
                </p>
                <p data-aos-duration="2000" data-aos="fade-right">
                  Unlike Alex, I didn’t need to wait for a letter inviting me to
                  Hogwarts. I was the person who sent them out. Because I used
                  to be Dumbledore’s rarely mentioned secretary.
                  <br />
                  Now I am the Friday Collective Word Witch: the sad one who
                  keeps a dictionary by her bed and devours grammar when
                  everyone else is eating pizza.
                  <br />
                  I love enchanting other people’s writing, making it leap off
                  the page with a flick of my wand. And I can’t get enough of my
                  favourite spell, conjuration. Give me a deadline, a theme (and
                  obviously an expensive bottle of Merlot!), and I happily
                  create content out of nothing.
                </p>
              </div>

              <div class="col-8 mx-auto col-md-4 mb-5 mb-md-0 my-auto">
                <img
                  data-aos-duration="2000"
                  data-aos="fade-right"
                  class="animated"
                  src="@/assets/images/team/kati.jpeg"
                  alt="Alex Friday Collective"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div class="col text-center">
            <router-link
              to="/join-the-collective"
              class="btn moving-gradient-button btn-lg"
              >Join the Collective</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
img.animated {
  animation: filter-animation 3s infinite;
}

@keyframes filter-animation {
  0% {
    filter: sepia(0.3) saturate(3) hue-rotate(200deg);
  }

  50% {
    filter: sepia(1) saturate(10) hue-rotate(300deg);
  }

  100% {
    filter: sepia(0.3) saturate(3) hue-rotate(270deg);
  }
}
</style>
